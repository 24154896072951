import axios from 'axios';

declare var process: {
  env: {
    NODE_ENV: string;
  };
};

const api = axios.create({
  //baseURL: `http://localhost:3003/v1/admin`,
  baseURL: `https://todaycrm.com.br/v1/admin`,
  //baseURL: `http://localhost:3003/v1/admin`, //prod
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token':
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9jb21wYW55IjoxLCJpYXQiOjE3MzQwNDcwMjIsImV4cCI6MjIzNDA0NzAyMn0.-J_0tvNAaGHCOVz0OoM1Wzl4hR1C8eNimuiDdThbvc8',
  },
});

api.defaults.withCredentials = true;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const access_token = localStorage.getItem('token');
    if (error.response.status === 401 && access_token) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
