import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: false,
    //guard: GuestGuard,
    path: '/privacy-policy',
    component: lazy(() => import('./views/landing/privacy'))
  },
  {
    exact: false,
    //guard: GuestGuard,
    path: '/payment',
    component: lazy(() => import('./views/landing/payment'))
  },
  {
    exact: true,
    //guard: GuestGuard,
    path: '/',
    component: lazy(() => import('./views/landing'))
  },
];

export default routes;
